<template>
  <BasicLayout :initVars="initVars">
    <template v-slot:layout-mainbox-inner>
      <span>Distribution</span>
    </template>
  </BasicLayout>
</template>

<script>
import BasicLayout from '@/layouts/basic';

export default {
  components: { BasicLayout },
  data () {
    return {
      initVars: {
      }
    };
  }
  // beforeCreate () {
  //   console.log('[Home][beforeCreate] ... ');
  // },
  // created () {
  //   console.log('[Home][created] ... ');
  // },
  // beforeMount () {
  //   console.log('[Home][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[Home][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[Home][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[Home][updated] ... ');
  // }
};
</script>
<style scoped>
</style>
